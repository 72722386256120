<template>
  <div class="form-wrapper">
    <b-row>
      <b-col md="6">
        <component
          :is="totalsFields['total_order_notes'].type"
          ref="total_order_notes_ref"
          v-model="totals['total_order_notes']"
          class="mb-0"
          :is-editable="isNotChangeable"
          v-bind="getProps('total_order_notes', totalsFields)"
        />
      </b-col>
      <b-col md="6">
        <!--    Totals Computed table   -->
        <h4 class="font-weight-bolder mb-1">
          {{ $t(TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE) }}
        </h4>
        <b-list-group>
          <b-list-group-item
            v-for="({ title,key}, index) in totalsComputedTable"
            :key="index"
            :class="`totals-form-list-group-item ${(index + 1) % 2 === 0 ? 'totals-form-list-group-item-light': ''}`"
          >
            <div class="d-flex justify-content-between">
              <span>
                {{ $t(title) }}
              </span>
              <span>
                {{ formatNumberToCurrencyView(calculateTotalProductPrice(key)) }}
              </span>
            </div>
          </b-list-group-item>
          <b-list-group-item class="totals-form-list-group-item">
            <div class="d-flex justify-content-between">
              <b>
                {{ $t(TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL) }}
              </b>
              <b>
                {{ getTotal }}
              </b>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { sumTotalAmountOfGivenArrayByProperty, getProps, formatNumberToCurrencyView } from '@core/utils/utils'

import config from '../config'

export default {
  name: 'Totals',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    isNotChangeable() {
      return this.quote.state === 2 || (this.quote.state === 0 && this.quote.status === 5)
    },
    totals() {
      return this.$store.state[this.MODULE_NAME].totalsForm
    },
    creditItems() {
      return this.$store.state[this.MODULE_NAME].creditItemsTable.creditItems
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    getTotal() {
      return formatNumberToCurrencyView(this.sumTotalAmountOfGivenArrayByProperty(this.creditItems.map(item => ({
        ...item,
        total: (+item.unit * +item.price) - +item.discount + +item.tax ?? 0,
      })), 'total'))
    },
  },
  methods: {
    calculateTotalProductPrice(key) {
      return this.sumTotalAmountOfGivenArrayByProperty(this.creditItems, key)
    },
  },
  setup() {
    const {
      MODULE_NAME,
      totalsFields,
      orderDetails,
      TOTALS_FORM_TITLE,
      totalsComputedTable,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      orderDetails,
      totalsFields,
      TOTALS_FORM_TITLE,
      totalsComputedTable,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL,
      sumTotalAmountOfGivenArrayByProperty,
      formatNumberToCurrencyView,
    }
  },

}
</script>
<style lang="scss">
.totals-form-list-group-item {
  padding: 5px 1.25rem !important;

  &:hover {
    background-color: transparent !important;
  }

  &-light {
    background-color: #FBFBFB !important;

    &:hover {
      background-color: #FBFBFB !important;
    }
  }

}
</style>
