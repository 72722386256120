<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div>
        <b-button
          v-if="true"
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="redirectList"
        >
          {{ $t("Back to List") }}
        </b-button>
        <b-button
          v-else
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t("Cancel") }}
        </b-button>
      </div>
      <div
        class="d-flex"
        style="gap: 12px"
      >
        <b-button
          style="background: #6f1214 !important;"
          class="font-medium-1"
          :disabled="loadingFor.isLoading || isCreateForm || isQuoteVoid || isDone"
          @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID)"
        >
          <feather-icon
            icon="LNoIcon"
            size="16"
          />
          {{ $t(FORM_BUTTON_TITLE_VOID) }}
        </b-button>
        <b-button
          class="font-medium-1"
          @click="printContent()"
        >
          <feather-icon
            icon="LPrintIcon"
            size="16"
          />
          {{ $t(FORM_BUTTON_TITLE_PRINT) }}
        </b-button>
        <b-button
          v-if="type !== 'paid'"
          class="font-medium-1 "
          :disabled=" isDone || isQuoteVoid"
          @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT "
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(FORM_BUTTON_TITLE_SAVE_AS_DRAFT) }}
          </span>
        </b-button>
        <b-button
          :disabled="loadingFor.isLoading || isQuoteSentByEmail || isDone || isQuoteVoid"
          class="font-medium-1 "
          @click="submit(CREATE_CREDIT_NOTE_SENT_BY_EMAIL)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === CREATE_CREDIT_NOTE_SENT_BY_EMAIL"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(FORM_BUTTON_TITLE_SEND_BY_EMAIL) }}
          </span>
        </b-button>
        <b-button
          v-if="type === 'paid'"
          :disabled="loadingFor.isLoading || isDone || isQuoteVoid"
          variant="success"
          class="font-medium-1"
          @click="submit(CREATE_CREDIT_NOTE_MARK_AS_PAID)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === CREATE_CREDIT_NOTE_MARK_AS_PAID "
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(FORM_BUTTON_TITLE_MARK_PAID) }}
          </span>
        </b-button>
        <b-button
          v-else
          :disabled="loadingFor.isLoading ||isDone || isQuoteVoid"
          variant="success"
          class="font-medium-1"
          @click="submit(CREATE_CREDIT_NOTE)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === CREATE_CREDIT_NOTE "
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(FORM_BUTTON_TITLE_QUOTE_RECEIVED) }}
          </span>
        </b-button>
      </div>
    </div>
  </portal>

</template>

<script>

import { BButton } from 'bootstrap-vue'
// import RFQPrintTemplate
import Printer from '@/views/components/Printer/Printer.vue'
import config from '@/views/main/orders/view/credit-note/config'
import { scrollToError } from '@core/utils/utils'
import jsonToFormData from '@/libs/jsonToFormData'
import { validate } from 'vee-validate'
import { printContent } from '@/views/main/orders/components/helpers'

export default {
  name: 'FormBottom',
  components: { Printer, BButton },
  props: {
    formRefs: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      loadingFor: {
        isLoading: false,
        action: null,
        disabled: true,
      },
    }
  },
  computed: {
    isCreateForm() {
      return !this.$route?.params?.id
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    isQuoteSentByEmail() {
      return this.quote.state === 0 && this.quote.status === 4
    },
    isQuoteVoid() {
      return this.quote.state === 0 && this.quote.status === 5
    },
    isDone() {
      return this.quote.state === 2
    },
    customerInformationForm() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    dispatchAndReturnForm() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    orderItemsRentalTable() {
      return this.$store.state[this.MODULE_NAME].orderItemsRentalTable
    },
    credits() {
      return this.$store.state[this.MODULE_NAME]?.creditItemsTable?.creditItems
    },
    totalsForm() {
      return this.$store.state[this.MODULE_NAME].totalsForm
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
    creditNoteFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].creditNoteFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.getTotalDataOfForms) !== this.creditNoteFormClone
    },

  },
  methods: {
    printContent,
    redirectList() {
      this.$router.push({ name: 'home-orders-purchase-cr' })
    },
    submit(action) {
      if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT) {
        setTimeout(() => {
          this.formRefs.createCreditNoteForm.reset()
        }, 0)
        setTimeout(() => {
          validate(this.customerInformationForm.customer_id, 'required|integer', { name: 'supplier-name' }).then(result => {
            if (!result.valid) {
              this.formRefs.supplierInformation.$refs.selectAccount.$refs.validationProvider.setErrors(['The Supplier field is required'])
              const element = document.getElementById('create_page_title')
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
                element.focus()
              }
            } else {
              this.submitLogic(action)
            }
          })
        }, 1)
      } else {
        this.formRefs.createCreditNoteForm.validate().then(success => {
          if (success) {
            this.submitLogic(action)
          } else {
            scrollToError(this, this.formRefs.createCreditNoteForm)
          }
        })
      }
    },
    redirectToRFQSend(id, action) {
      this.$store.commit(`${this.MODULE_NAME}/SET_RE_RENDER_COMPONENT_KEY`)
      if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT) {
        if (!this.quote.id) {
          this.$router.push({
            name: 'credit-note-update-quote',
            params: { id },
          })
        }
      } else {
        this.$router.push({
          name: 'credit-note-paid-quote',
          params: { id },
        })
      }
    },
    clearForm() {

    },
    submitLogic(action) {
      const { customerInformationForm, totalsForm, credits } = this
      const sendData = {
        action,
        id: this.quote?.id,
        _method: this.quote?.id && 'patch',
        customer_id: customerInformationForm.customer_id,
        billing_contact_id: customerInformationForm.billing_contact?.id,
        billing_address_id: customerInformationForm?.billing_address_id ? customerInformationForm.billing_address_id : customerInformationForm?.billing_address?.id,
        payment_term_id: customerInformationForm.payment_term_id?.id,
        internal_order_notes: customerInformationForm.internal_order_reference,
        credit_note_order: totalsForm.total_order_notes,
        attachments: customerInformationForm?.attachments,
        other_charges: credits?.map(i => ({
          ...i,
          discount: i.discount || 0,
          tax: i.tax || 0,
          unit: i.unit || 0,
          price: i.price || 0,
          subtotal: i.subtotal || 0,
        })),
      }
      const contactsFormData = jsonToFormData(sendData)
      this.sendNotification(this, contactsFormData, `${this.MODULE_NAME}/${this.quote?.id ? 'updateCreditNote' : 'create'}`, 'Credit Node Successfully')
        .then(data => {
          const { id } = data.data.data
          this.redirectToRFQSend(id, action)
          this.$emit('clear')
        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
        })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      FORM_BUTTON_TITLE_VOID,
      FORM_BUTTON_TITLE_PRINT,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      FORM_BUTTON_TITLE_SEND_BY_EMAIL,
      FORM_BUTTON_TITLE_QUOTE_RECEIVED,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      CREATE_CREDIT_NOTE,
      CREATE_CREDIT_NOTE_SENT_BY_EMAIL,
      FORM_BUTTON_TITLE_MARK_PAID,
      CREATE_CREDIT_NOTE_MARK_AS_PAID,
    } = config()

    return {
      MODULE_NAME,
      FORM_BUTTON_TITLE_VOID,
      FORM_BUTTON_TITLE_PRINT,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      FORM_BUTTON_TITLE_SEND_BY_EMAIL,
      FORM_BUTTON_TITLE_QUOTE_RECEIVED,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      CREATE_CREDIT_NOTE,
      CREATE_CREDIT_NOTE_SENT_BY_EMAIL,
      FORM_BUTTON_TITLE_MARK_PAID,
      CREATE_CREDIT_NOTE_MARK_AS_PAID,
    }
  },
}
</script>

<style>

</style>
