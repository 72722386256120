<template>
  <div
    ref="credit-ref"
    class="form-wrapper mt-1"
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t(CREDIT_TITLE) }}
    </h3>
    <div
      style="border-radius: 5px"
    >
      <b-table-simple
        :class="`credit-items-table credit-items ${tableItemsLength === 1 ? 'credit-items-table--item-one': ''}`"
      >
        <b-thead>
          <b-tr>
            <b-td
              v-for="{key, label, thStyle} in tableColumnsCreditItems"
              :key="key"
              :style="thStyle"
              class="font-weight-bolder"
            >
              {{ label }}
            </b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <tr
            v-for="({unit}, index) in creditItemsOtherChargesArr"
            :key="index"
          >
            <td
              class="p-0"
              style="padding-left: 12px !important;"
            >
              <feather-icon
                icon="LTrashIcon"
                size="30"
                style="padding: 4px"
                class="cursor-pointer mr-1 border-dotted feather-trash-icon defaultIconColor"
                @click="remove(index)"
              />
            </td>
            <td class="text-left pl-0">
              <component
                :is="chargeModelFields['name'].type"
                v-model="creditItemsOtherChargesArr[index]['name']"
                class="credit-items__name"
                v-bind="getProps('name', chargeModelFields)"
              />
            </td>
            <td>
              <b-form-input
                v-model="creditItemsOtherChargesArr[index]['unit']"
                class="credit-items__name credit-items__name--text-center credit-items__name--b-form-input"
                type="number"
              />
            </td>
            <td>
              <component
                :is="chargeModelFields['price'].type"
                v-model="creditItemsOtherChargesArr[index]['price']"
                class="credit-items__name credit-items__name--text-center"
                v-bind="getProps('price', chargeModelFields)"
              />
            </td>
            <td>
              <span>{{ formatNumberToCurrencyView((+creditItemsOtherChargesArr[index]['unit'] * +creditItemsOtherChargesArr[index]['price'] )) }}</span>
            </td>
            <td>
              <component
                :is="chargeModelFields['discount'].type"
                v-model="creditItemsOtherChargesArr[index]['discount']"
                class="credit-items__name credit-items__name--text-center"
                v-bind="getProps('discount', chargeModelFields)"
              />
            </td>
            <td>
              <component
                :is="chargeModelFields['tax'].type"
                v-model="creditItemsOtherChargesArr[index]['tax']"
                class="credit-items__name credit-items__name--text-center"
                v-bind="getProps('tax', chargeModelFields)"
              />
            </td>

            <td>
              <span>{{ getTotalLine(creditItemsOtherChargesArr[index]) }}</span>
            </td>
          </tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex">
        <b-form-input
          ref="charge-input-name"
          v-model="chargeName"
          style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
          :class="`charge-input border-right-0 ${tableItemsLength ? 'charge-input-name' : ''}`"
          :placeholder="$t('Type...')"
          @keydown.tab.prevent="createChargeItem($event)"
          @keyup.enter="createChargeItem($event)"
        />
      </div>
      <b-table-simple
        class="credit-items-table"
      >
        <b-thead>
          <b-tr>
            <b-td
              v-for="{key, thStyle} in chargeModelFields"
              :key="key"
              :style="thStyle"
              style="height: 0; padding: 0"
            />
          </b-tr>
        </b-thead>
        <b-tfoot>
          <tr>
            <td />
            <td
              class="text-right"
              style="width: 56%"
            >
              <b>{{ $t('TOTAL') }}</b>
            </td>
            <td class="totalTd">
              <b>{{ getTotalItemsLen }}</b>
            </td>
            <td class="totalTd">
              <b>{{ getTotalPrice }}</b>
            </td>
            <td class="totalTd">
              <b>{{ getTotalSubtotal }}</b>
            </td>
            <td class="totalTd">
              <b>{{ getTotalDiscount }}</b>
            </td>
            <td class="totalTd">
              <b>{{ getTotalTaxes }}</b>
            </td>
            <td class="totalTd">
              <b>{{ getTotal }}</b>
            </td>
          </tr>
        </b-tfoot>
      </b-table-simple>
    </div>
  </div>

</template>
<script>
import config from '@/views/main/orders/view/credit-note/config'
import { formatNumberToCurrencyView, getProps, sumTotalAmountOfGivenArrayByProperty } from '@core/utils/utils'
import {
  BFormInput, BTableSimple, BTbody, BTd, BTfoot, BThead, BTr,
} from 'bootstrap-vue'
import LCurrencyMaskInput from '@/views/components/DynamicForm/components/LCurrencyMaskInput.vue'

export default {
  name: 'Credit',
  components: {
    BTr,
    BTd,
    BThead,
    BTbody,
    BTfoot,
    BFormInput,
    BTableSimple,
    LCurrencyMaskInput,
  },
  data() {
    return {
      chargeName: '',
      subTotal: '',
      taxRule: null,
    }
  },
  computed: {
    creditItemsOtherChargesArr() {
      return this.creditItems.creditItems
    },
    creditItems() {
      return this.$store.state[this.MODULE_NAME].creditItemsTable
    },
    // tax() {
    //   return this.taxRule?.value ?? 0
    // },
    getTotalItemsLen() {
      return this.sumTotalAmountOfGivenArrayByProperty(this.creditItemsOtherChargesArr, 'unit')
    },
    getTotalPrice() {
      return formatNumberToCurrencyView(this.sumTotalAmountOfGivenArrayByProperty(this.creditItemsOtherChargesArr, 'price'))
    },
    getTotalSubtotal() {
      return formatNumberToCurrencyView(this.sumTotalAmountOfGivenArrayByProperty(this.creditItemsOtherChargesArr.map(i => ({
        ...i,
        subtotal: (+i.unit * +i.price),
      })), 'subtotal'))
    },
    getTotalDiscount() {
      return formatNumberToCurrencyView(this.sumTotalAmountOfGivenArrayByProperty(this.creditItemsOtherChargesArr, 'discount'))
    },
    getTotalTaxes() {
      return formatNumberToCurrencyView(this.sumTotalAmountOfGivenArrayByProperty(this.creditItemsOtherChargesArr, 'tax'))
    },
    getTotal() {
      return formatNumberToCurrencyView(this.sumTotalAmountOfGivenArrayByProperty(this.creditItemsOtherChargesArr.map(item => ({
        ...item,
        total: (+item.unit * +item.price) - +item.discount + +item.tax ?? 0,
      })), 'total'))
    },
    tableItemsLength() {
      return this.creditItemsOtherChargesArr.length
    },
  },
  methods: {
    createChargeItem() {
      this.creditItems.creditItems.push({
        ...this.creditModel,
        name: this.chargeName,
      })
      this.chargeName = ''
      this.$refs['charge-input-name'].focus()
    },
    remove(index) {
      this.creditItems.creditItems = this.creditItemsOtherChargesArr.filter((_, idx) => idx !== index)
    },

    getTotalLine(value) {
      const totalPrice = (+value.unit * +value.price) - +value.discount + +value.tax ?? 0
      return formatNumberToCurrencyView(totalPrice)
    },
  },
  setup() {
    const {
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      CREDIT_TITLE,
      chargeModelFields,
      creditModel,
      tableColumnsCreditItems,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      CREDIT_TITLE,
      chargeModelFields,
      creditModel,
      tableColumnsCreditItems,
      sumTotalAmountOfGivenArrayByProperty,
      formatNumberToCurrencyView,
    }
  },
}
</script>

<style lang="scss">
.credit-items-table {
  margin-bottom: 0;

  thead td,
  tfoot td {
    border: 0 !important;
  }

  td {
    padding: 5px 12px;
  }

  tbody td,
  tfoot td {
    text-align: center;
  }

  tbody tr {
    height: 36px;
  }

  tbody td {
    padding: 0 12px;
  }

  tbody tr:last-child td:first-child {
    border-radius: 0 !important;
  }

  tbody tr:last-child td:last-child {
    border-radius: 0 !important;
  }

  &--item-one {
    tbody tr:last-child td:first-child {
      border-radius: 5px 0 0 0 !important;
    }

    tbody tr:last-child td:last-child {
      border-radius: 0 5px 0 0 !important;
    }
  }

  .credit-items__name {
    margin-bottom: 0;

    & input {
      width: 90%;
      padding: 0 2px;
      background: transparent;
      height: 30px;
      border-color: transparent;

      &:hover,
      &:focus {
        border: 1px solid #DDE3E7;
        box-shadow: none;
      }
    }

    &--text-center {
      input {
        width: 100%;
        text-align: center;
      }
    }

    &--b-form-input {
      width: 100%;
      text-align: center;
      padding: 0 2px;
      background: transparent;
      height: 30px;
      border-color: transparent;

      &:hover,
      &:focus {
        border: 1px solid #DDE3E7;
        box-shadow: none;
      }
    }
  }

  .credit-items__mask {
    margin-bottom: 0;
    width: 100%;

    & .form-control {
      text-align: center;
      padding: 0 4px;
      height: 32px !important;
      background: transparent !important;
      border-color: transparent;

      &:hover,
      &:focus {
        border: 1px solid #DDE3E7;
      }
    }
  }

}

.charge-input {
  height: 36px;

  &:focus {
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid #DDE3E7;
  }

}

.charge-input-name {
  border-radius: 0 0 0 5px;
}

.charge-input-number {
  border-radius: 0 0 5px 0;
}

.charge-input-name,
.charge-input-number {
  border-top: none;

  &:focus {
    border-top: none;
  }
}

.direct-req {
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid #DDE3E7 !important;
}

.totalTd{
  padding-right: 0 !important;
  text-align: right !important;
}
</style>
